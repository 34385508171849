<script>
/**
 * Product contents sub view | Sub view of a Product
 *
 * @property {Boolean} isPlannable true iff the user has rights to plan contents
 * @property {Boolean} isFrozen true iff product content cannot be edited
 *
 * Per product content:
 * @property {Date} actualSubmissionDate
 * @property {Boolean} correction product content is with / without correction
 * @property {Boolean} currentUserHasResponsibleRole if the current user has a role that allows doing things with this product content
 **/
import IconButton from '@/components/IconButton.vue'
import Table from '@/components/Table.vue'
import TextInput from '@/components/TextInput.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import NumberInput from '@/components/NumberInput.vue'
import DateInput from '@/components/DateInput.vue'
import Icon from '@/components/Icon.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import Button from '@/components/Button.vue'
import InlineConfirmButton from '@/components/InlineConfirmButton.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import { ProductContentStatus } from '@/types/ProductContentStatus'
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'
import { userHandler } from '@/mixins/userHandler'
import { basicHelper } from '@/mixins/basicHelper'
import { productLinkHandler } from '@/mixins/productLinkHandler'
import { environmentHandler } from '@/mixins/environmentHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductContents',
    mixins: [
        axiosService,
        notificationHandler,
        taskAssignmentHandler,
        userHandler,
        basicHelper,
        productLinkHandler,
        environmentHandler,
        dateTimeHelper
    ],
    components: {
        IconButton,
        Table,
        TextInput,
        SmartSelect,
        InteractionLink,
        NumberInput,
        DateInput,
        Icon,
        BadgeInput,
        Button,
        InlineConfirmButton,
        VerticalLine
    },
    props: {
        productState: Object
    },
    data () {
        return {
            forceRerenderKey: 0,
            productInfo: this.$attrs.productInfo || this.productState,
            isPlannable: false,
            isFrozen: false,
            showFrozenBadge: false,
            contentList: [],
            selectedContentIds: [],
            contentFields: {
                NAME: 'name',
                CORRECTION: 'correction',
                PAGES: 'pages',
                SUBMISSION: 'submissionDate',
                ACTUAL_SUBMISSION: 'actualSubmissionDate'
            },
            newContentName: 'new-content-element',
            newContent: {
                id: 'newContent',
                name: '',
                correction: true,
                pages: null,
                submissionDate: '',
                wasEdited: false,
                visible: false
            },
            widths: {
                selected: 8,
                name: 32,
                pages: 15,
                submissionDate: 15,
                assignee: 15,
                actions: 15
            },
            showSpinner: false,
            isSelectedColumnVisible: true
        }
    },
    methods: {
        forceReload () {
            this.forceRerenderKey += 1
        },
        closeActiveInputs () {
            this.contentList.forEach(content => {
                Object.keys(this.contentFields).forEach(key => {
                    if (key === this.contentFields.ACTUAL_SUBMISSION) {
                        // field is not shown in the table directly, can't be edited
                        return
                    }
                    const field = this.contentFields[key]
                    if (!this.isContentMissingValue(content, field)) {
                        this.setEditModeForField(content, field, false, false)
                    }
                })
            })
            this.$forceUpdate()
        },

        getContentList () {
            this.axiosGet(
                `contents/v2/${this.productInfo.id}`,
                this.$tc('product.contents.getContentListError'))
                .then(contentList => {
                    this.isPlannable = contentList.isPlannable
                    this.isFrozen = contentList.isFrozen
                    this.showFrozenBadge = contentList.isFrozen && (this.isTestEnvironment() || this.isLocalEnvironment())
                    contentList.contents.forEach(content => {
                        this.setupContentForTableView(content)
                        this.setupRightsForExecutableActions(content)
                    })
                    this.contentList = contentList.contents
                })
                .catch(error => {
                    console.error(error)
                })
        },

        setupContentForTableView (content) {
            content.rolesDisplayText = content.assignee || content.roles.map(role => this.$tc(`generals.roleLabels.${role}`)).join(', ')

            if (content.submissionDate || content.tatInfo.turnaroundDateString) {
                content.submissionDateDisplayText = this.getSubmissionDateDisplayText(content)

                // use a dedicated sorting key
                content.submissionAndDueDateSortingKey = this.getSubmissionDate(content)
            }
        },

        setupRightsForExecutableActions (content) {
            const statusEquals = (s) => content.status === s
            const statusDoesNotEqual = (s) => content.status !== s

            function assignmentCheckPassed (username) {
                return !content.assignee || content.assignee === username
            }

            const currentUsername = this.getUserName()
            content.executableActions = {
                select: statusDoesNotEqual(ProductContentStatus.DELETED) && statusDoesNotEqual(ProductContentStatus.MIGRATED_FINAL) && assignmentCheckPassed(currentUsername) && content.currentUserHasResponsibleRole,
                delete: statusEquals(ProductContentStatus.CREATED) && content.currentUserHasResponsibleRole,
                progress: statusDoesNotEqual(ProductContentStatus.DELETED) && statusDoesNotEqual(ProductContentStatus.FINAL) && statusDoesNotEqual(ProductContentStatus.MIGRATED_FINAL) && assignmentCheckPassed(currentUsername) && content.currentUserHasResponsibleRole,
                repeat: statusEquals(ProductContentStatus.FINAL) && content.currentUserHasResponsibleRole,
                editTitle: statusDoesNotEqual(ProductContentStatus.DELETED) && assignmentCheckPassed(currentUsername) && content.currentUserHasResponsibleRole,
                editType: statusEquals(ProductContentStatus.CREATED) && content.currentUserHasResponsibleRole,
                editPages: statusDoesNotEqual(ProductContentStatus.DELETED) && statusDoesNotEqual(ProductContentStatus.FINAL) && statusDoesNotEqual(ProductContentStatus.MIGRATED_FINAL) && assignmentCheckPassed(currentUsername) && content.currentUserHasResponsibleRole,
                editSubmission: statusEquals(ProductContentStatus.CREATED) && content.currentUserHasResponsibleRole && !content.actualSubmissionDate
            }
        },

        isContentSelected (content) {
            return this.selectedContentIds.includes(content.id)
        },

        areAllSelected () {
            return this.$refs.productContentsTable
                ? this.$refs.productContentsTable.getTableRows.every(row => this.isContentSelected(row) || !row.executableActions.select)
                : false
        },

        toggleSelected (content) {
            if (this.isContentSelected(content)) {
                const index = this.selectedContentIds.indexOf(content.id)
                this.selectedContentIds.splice(index, 1)
            } else {
                this.selectedContentIds.push(content.id)
            }
        },

        toggleAllSelected () {
            if (this.areAllSelected()) {
                this.resetSelectedContents()
            } else {
                const visibleRowIds = this.$refs.productContentsTable.getTableRows.map(row => row.id)
                this.selectedContentIds = this.contentList
                    .filter(content => content.executableActions.select && visibleRowIds.includes(content.id))
                    .map(content => content.id)
            }
            this.$forceUpdate()
        },

        resetSelectedContents () {
            this.selectedContentIds = []
        },

        executeSelectedContentsAction (selectSubmitEventData) {
            const action = selectSubmitEventData.option.action
            if (this[action]) {
                this[action]()
            }
        },

        getSubmissionDateDisplayText (content) {
            if (this.hasValidDueDate(content)) {
                return this.getDueDateText(content)
            }
            if (content.submissionDate && content.status === ProductContentStatus.CREATED) {
                return this.getFormattedShortDate(content.submissionDate, this.locale) + ' (SOLL)'
            }
            return '-'
        },

        getSubmissionDate (content) {
            if (this.hasValidDueDate(content)) {
                return this.getDueDate(content)
            }
            if (content.submissionDate && content.status === ProductContentStatus.CREATED) {
                return content.submissionDate
            }
            return '-'
        },

        isOverdue (content) {
            return content.tatInfo && content.tatInfo.overdue
        },

        getDueDate (content) {
            return content?.tatInfo?.turnaroundDateString
        },

        getDueDateText (content) {
            const dueDateString = this.getDueDate(content)
            return this.isDateRegex(dueDateString)
                ? this.getFormattedShortDate(dueDateString, this.locale)
                : dueDateString
        },

        setEditModeForField (content, field, editing, redraw = true) {
            content[`${field}Editing`] = editing
            if (redraw) {
                this.$forceUpdate()
            }
        },

        isInEditMode (content, field) {
            return content[`${field}Editing`]
        },

        updateContentField (inputSubmitEventData, content, field) {
            function hasValidValue () {
                return inputSubmitEventData.value && inputSubmitEventData.value !== ''
            }
            function updateConditionsFulfilled (appContext) {
                const hasPagesValue = !appContext.isContentMissingValue(content, appContext.contentFields.PAGES)

                // Note: Only in state CREATED, the submission date can be edited, and only then we have to check.
                //       After state CREATE submission date is null backend side and not relevant any more
                //       but replaced by the TATs
                const submissionDateCanBeChanged = content.executableActions.editSubmission
                const hasValidSubmissionValue = !(submissionDateCanBeChanged && appContext.isContentMissingValue(content, appContext.contentFields.SUBMISSION))

                return hasPagesValue && hasValidSubmissionValue
            }

            if (hasValidValue()) {
                content[field] = inputSubmitEventData.value
                if (updateConditionsFulfilled(this)) {
                    this.updateContent(content)
                } else {
                    this.configureNotification(
                        'warning',
                        { standard: 5000, short: 3000 },
                        {
                            standard: this.$t('product.contents.emptyValueWarning.standard'),
                            short: this.$t('product.contents.emptyValueWarning.short')
                        })
                }
            } else {
                this.configureNotification(
                    'warning',
                    { standard: 5000, short: 3000 },
                    {
                        standard: this.$t('product.contents.updateContentWarning.standard'),
                        short: this.$t('product.contents.updateContentWarning.short')
                    })
            }
            this.setEditModeForField(content, field, false)
        },

        showNewContentForm () {
            this.newContent.visible = true
        },

        updateNewContentFormField (inputChangeEventData, field) {
            this.newContent[field] = inputChangeEventData.value
        },

        resetNewContent () {
            this.newContent.name = ''
            this.newContent.correction = true
            this.newContent.pages = null
            this.newContent.submissionDate = null
            this.newContent.visible = false
        },

        isContentInProgress (content) {
            return content.status !== ProductContentStatus.CREATED
        },

        isContentFinalized (content) {
            return content.status === ProductContentStatus.FINAL || content.status === ProductContentStatus.MIGRATED_FINAL
        },

        isOldContentFinalized (content) {
            return content.status === ProductContentStatus.MIGRATED_FINAL
        },

        isContentMissingValue (content, field) {
            if (field === this.contentFields.PAGES && content[field] === 0) {
                return false
            }
            return !content[field] || content[field] === ''
        },

        toDateString (timeStamp) {
            return this.toBerlinDateAsISOString(timeStamp)
        },

        requestPayloadFromContentFields (content) {
            const data = {}
            Object.keys(this.contentFields).forEach(key => {
                const field = this.contentFields[key]

                if (field === this.contentFields.SUBMISSION || field === this.contentFields.ACTUAL_SUBMISSION) { // set format as expected by the backend
                    data[field] = this.toDateString(content[field])
                } else {
                    data[field] = content[field]
                }
            })
            if (data[this.contentFields.ACTUAL_SUBMISSION]) {
                // undo the hack we used for displaying both date fields in 1 column
                delete data[this.contentFields.SUBMISSION]
            }
            return data
        },

        addContent (keepNewContentFormOpen) {
            const data = this.requestPayloadFromContentFields(this.newContent)
            this.axiosPost(
                `contents/v2/${this.productInfo.id}`,
                data,
                this.$t('product.contents.newContent.addContentError', [this.newContent.name]),
                {
                    standard: this.$t('product.contents.newContent.addContentSuccess.standard', [this.newContent.name]),
                    short: this.$t('product.contents.newContent.addContentSuccess.short', [this.newContent.name])
                })
                .then(addedContent => {
                    this.setupContentForTableView(addedContent)
                    this.setupRightsForExecutableActions(addedContent)
                    // .info message will be displayed above the content name in the table
                    addedContent.info = this.$tc('product.contents.table.newLabel')
                    this.contentList.push(addedContent)
                    if (keepNewContentFormOpen) {
                        document.getElementById(this.newContentName)
                            .getElementsByTagName('input')[0]
                            .focus()
                    } else {
                        this.resetNewContent()
                    }
                    this.vueNextTick(() => document
                        .getElementById(`table-row_${addedContent.id}`)
                        .scrollIntoView({ block: 'start', behavior: 'smooth' }))
                })
                .catch(() => {})
        },

        updateContent (content) {
            const data = this.requestPayloadFromContentFields(content)
            this.axiosPut(
                `contents/v2/${this.productInfo.id}/${content.id}`,
                data,
                this.$t('product.contents.updateContentError', [content.name]),
                {
                    standard: this.$t('product.contents.updateContentSuccess.standard', [content.name]),
                    short: this.$t('product.contents.updateContentSuccess.short', [content.name])
                })
                .then((updatedProduct) => {
                    content.wasEdited = updatedProduct.wasEdited
                    this.setupContentForTableView(content)
                })
                .catch(() => {})
        },

        removeContent (content) {
            this.axiosDelete(
                `contents/v2/${this.productInfo.id}/${content.id}`,
                null,
                this.$t('product.contents.removeContentError', [content.name]),
                {
                    standard: this.$t('product.contents.removeContentSuccess.standard', [content.name]),
                    short: this.$t('product.contents.removeContentSuccess.short', [content.name])
                })
                .then(() => {
                    if (this.isContentSelected(content)) {
                        this.toggleSelected(content)
                    }
                    this.contentList.splice(this.contentList.indexOf(content), 1)
                })
                .catch(() => {})
        },

        removeSelectedContents () {
            function executeDelete (appContext) {
                appContext.axiosDelete(
                    `contents/v2/${appContext.productInfo.id}`,
                    appContext.selectedContentIds,
                    appContext.$t('product.contents.removeContentsError'),
                    {
                        standard: appContext.$t('product.contents.removeContentsSuccess.standard', [appContext.selectedContentIds.length]),
                        short: appContext.$t('product.contents.removeContentsSuccess.short', [appContext.selectedContentIds.length])
                    })
                    .then(deletedContentIds => {
                        deletedContentIds.forEach(contentId => {
                            const index = appContext.contentList.findIndex(content => content.id === contentId)
                            appContext.toggleSelected(appContext.contentList[index])
                            appContext.contentList.splice(index, 1)
                        })
                        appContext.resetSelectedContents()
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }

            this.$swal({
                title: this.$t('product.contents.removeContentsPopup.title', [this.selectedContentIds.length]),
                text: this.$tc('product.contents.removeContentsPopup.text'),
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: this.$tc('product.contents.removeContentsPopup.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('generals.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    executeDelete(this)
                }
            })
        },

        divideContent () {
            const selectedContentId = this.selectedContentIds[0]
            this.showSpinner = false
            this.axiosPost(
                `contents/v2/${this.productInfo.id}/${selectedContentId}/divide`,
                null,
                this.$t('product.contents.divideContentError'),
                {
                    standard: this.$t('product.contents.divideContentSuccess.standard'),
                    short: this.$t('product.contents.divideContentSuccess.short')
                })
                .then(dividedContentResult => {
                    this.showSpinner = true
                    this.resetContentInfoTags()
                    this.setupContentForTableView(dividedContentResult[0])
                    this.setupContentForTableView(dividedContentResult[1])
                    this.setupRightsForExecutableActions(dividedContentResult[0])
                    this.setupRightsForExecutableActions(dividedContentResult[1])
                    dividedContentResult[0].info = `${this.$tc('product.contents.table.dividedLabel')} - 1`
                    dividedContentResult[1].info = `${this.$tc('product.contents.table.dividedLabel')} - 2`
                    const dividedContentIndex = this.contentList.findIndex(content => content.id === selectedContentId)
                    this.contentList.splice(dividedContentIndex, 1, ...dividedContentResult)
                    this.resetSelectedContents()
                    this.triggerReload(this)
                })
                .catch((error) => {
                    console.error(`Error: ${error}`)
                    // otherwise, default error handling is enough
                })
        },

        mergeSelectedContents () {
            this.showSpinner = false
            const skipProductContentsMergePopup = this.getUserPreference('skipProductContentsMergePopup')
            if (skipProductContentsMergePopup) {
                this.executeMerge(this)
            } else {
                this.$swal({
                    title: this.$t('product.contents.mergeContentsPopup.title', [this.selectedContentIds.length]),
                    text: this.$tc('product.contents.mergeContentsPopup.text'),
                    icon: 'warning',
                    showConfirmButton: true,
                    confirmButtonText: this.$tc('product.contents.mergeContentsPopup.confirmText'),
                    showCancelButton: true,
                    cancelButtonText: this.$tc('generals.cancel'),
                    input: 'checkbox',
                    inputPlaceholder: this.$tc('product.contents.mergeContentsPopup.skipPopup'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.isConfirmed) {
                        this.executeMerge(this)
                        const checkboxValue = result.value
                        this.setUserPreference('skipProductContentsMergePopup', checkboxValue === 1)
                    }
                })
            }
        },

        executeMerge (appContext) {
            appContext.axiosPost(
                `contents/v2/${appContext.productInfo.id}/merge`,
                appContext.selectedContentIds,

                appContext.$t('product.contents.mergeContentsError'),
                {
                    standard: appContext.$t('product.contents.mergeContentsSuccess.standard'),
                    short: appContext.$t('product.contents.mergeContentsSuccess.short')
                })
                .then(mergedContent => {
                    appContext.showSpinner = true
                    appContext.resetContentInfoTags()
                    appContext.setupContentForTableView(mergedContent)
                    appContext.setupRightsForExecutableActions(mergedContent)
                    mergedContent.info = appContext.$tc('product.contents.table.mergedLabel')
                    appContext.selectedContentIds.forEach((contentId, loopIndex) => {
                        const tableIndex = appContext.contentList.findIndex(content => content.id === contentId)
                        if (loopIndex === 0) {
                            appContext.contentList.splice(tableIndex, 1, mergedContent)
                        } else {
                            appContext.contentList.splice(tableIndex, 1)
                        }
                    })
                    appContext.resetSelectedContents()
                    appContext.triggerReload(appContext)
                })
                .catch((error) => {
                    console.error(`Error: ${error}`)
                    // otherwise, default error handling is enough
                })
        },

        triggerReload (appContext) {
            setTimeout(() => {
                appContext.getContentList()
                appContext.showSpinner = false
            }, 3000)
        },

        updateExecutionRightsAndTableEntry (content) {
            this.setupRightsForExecutableActions(content)
            const contentIndex = this.contentList.findIndex(_content => _content.id === content.id)
            this.contentList.splice(contentIndex, 1, content)
        },

        moveContentForward (content) {
            if (content.taskId) {
                const task = { id: content.taskId, assignee: content.assignee, name: content.taskName }
                this.assignTask(task, true)
            } else {
                this.startContentWithCorrectionTask(content)
            }
        },

        startContentWithCorrectionTask (content) {
            this.$swal({
                title: this.$tc('product.contents.startContentPopup.title'),
                text: this.$tc('product.contents.startContentPopup.text'),
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: this.$tc('product.contents.startContentPopup.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('product.contents.startContentPopup.cancelText'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    this.axiosPost(
                        `contents/v2/${this.productInfo.id}/${content.id}/start`,
                        null,
                        this.$t('product.contents.startContentError', [content.name]),
                        {
                            standard: this.$t('product.contents.startContentSuccess.standard', [content.name]),
                            short: this.$t('product.contents.startContentSuccess.short', [content.name])
                        })
                        .then(startedContent => {
                            this.updateExecutionRightsAndTableEntry(startedContent)
                            const task = {
                                id: startedContent.taskId,
                                assignee: startedContent.assignee,
                                name: startedContent.taskName
                            }
                            this.assignTask(task, true)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
        },

        restartContent (content) {
            this.axiosPost(
                `contents/v2/${this.productInfo.id}/${content.id}/restart`,
                null,
                this.$t('product.contents.restartContentError', [content.name]),
                {
                    standard: this.$t('product.contents.restartContentSuccess.standard', [content.name]),
                    short: this.$t('product.contents.restartContentSuccess.short', [content.name])
                })
                .then(restartedContent => {
                    this.showSpinner = true
                    this.updateExecutionRightsAndTableEntry(restartedContent)
                    this.triggerReload(this)
                })
                .catch(() => {
                })
        },

        wrapperKeyPressedHandler (event) {
            const keycode = (event.keyCode ? event.keyCode : event.which)
            // if + or numpad +
            if (keycode === 187 || keycode === 107) {
                this.showNewContentForm()
            }
        },

        newContentKeyPressedHandler (event) {
            if (event.key === 'Escape') {
                this.resetNewContent()
            } else if (event.key === 'Enter') {
                const keepFormOpen = !event.shiftKey
                if (!this.addContentButtonDisabled) {
                    this.addContent(keepFormOpen)
                }
            }
        },

        getContentPositionInSelection (content) {
            return this.selectedContentIds.indexOf(content.id) + 1
        },

        resetContentInfoTags () {
            this.contentList.forEach(content => {
                delete content.info
            })
        },

        getUserTagTooltip (content) {
            return this.$t('product.contents.table.userIsActivelyProgressingContent', [content.assignee])
        },

        hasValidDueDate (content) {
            return this.isDateRegex(content.tatInfo?.turnaroundDateString)
        },

        getIconClassForSubmissionDate (content) {
            if (this.hasValidDueDate(content)) {
                return content.tatInfo?.isManuallySet ? 'fas fa-user-edit' : 'fas fa-calculator'
            }
            if (content.status === ProductContentStatus.CREATED && content.wasEdited) {
                return 'fas fa-user-edit'
            }
            return ''
        },

        getTooltipForSubmissionDate (content) {
            if (content.wasEdited) {
                return this.$t('product.contents.table.wasEdited')
            }
            return ''
        },

        getIconClassForStatusButton (row, isFrozen) {
            if (isFrozen) {
                return 'fas fa-lock'
            }
            if (this.isContentFinalized(row)) {
                return 'fas fa-undo'
            }
            return 'fas fa-play-circle'
        },

        getToolTipForStatus (status, isFrozen) {
            if (isFrozen) {
                return this.$tc('product.contents.table.contentFrozen')
            }
            return this.$tc('product.contents.statusNameAndAction.' + status)
        },

        getToolTipMessageForStartedAndFinalContents (content) {
            const isFinalized = this.isContentFinalized(content)
            const isOldFinalized = this.isOldContentFinalized(content)
            if (isFinalized) {
                return isOldFinalized
                    ? this.$tc('product.contents.table.oldContentFinished')
                    : this.$tc('product.contents.table.contentFinished')
            }
            return this.$tc('product.contents.table.contentStarted')
        },

        handleColumnVisibilityChange (settings) {
            this.isSelectedColumnVisible = settings.find(c => c.key === 'selected').visible
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'selected',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                exportable: false,
                width: this.widths.selected
            }, {
                key: 'name',
                label: this.$tc('product.contents.table.contentName'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                breakAnywhere: true,
                width: this.widths.name
            }, {
                key: 'pages',
                label: this.$tc('product.contents.table.pages'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: this.widths.pages
            }, {
                key: 'submissionDateDisplayText',
                sortKey: 'submissionAndDueDateSortingKey',
                label: this.$tc('product.contents.table.submissionDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: this.widths.submissionDate
            }, {
                key: 'rolesDisplayText',
                label: this.$tc('product.contents.table.role'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: this.widths.assignee
            }, {
                key: 'actions',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                exportable: false,
                width: this.widths.actions
            }]
        },

        getTableEmptyText () {
            return this.isPlannable
                ? this.$tc('product.contents.table.tableEmpty')
                : this.$tc('product.contents.table.noContentCreation')
        },

        addContentButtonDisabled () {
            const requiredFields = [this.contentFields.NAME, this.contentFields.PAGES, this.contentFields.SUBMISSION]
            return requiredFields.some(field => this.isContentMissingValue(this.newContent, field))
        },

        getPagesCount () {
            let pageCount = 0
            this.contentList.forEach(content => {
                if (content.pages) {
                    pageCount += parseInt(content.pages)
                }
            })
            return pageCount
        },

        getSelectedContentsActions () {
            const noOrMultipleContentsSelected = this.selectedContentIds.length !== 1
            const lessThanTwoContentsSelected = this.selectedContentIds.length < 2
            const noContentSelected = this.selectedContentIds.length === 0
            const runningOrFinishedContentsSelected = this.contentList.some(
                content => this.isContentSelected(content) && (this.isContentInProgress(content) || this.isContentFinalized(content))
            )
            return [{
                label: this.$tc('product.contents.selectActions.divideContent'),
                action: 'divideContent',
                disabled: noOrMultipleContentsSelected
            }, {
                label: this.$tc('product.contents.selectActions.mergeContents'),
                action: 'mergeSelectedContents',
                disabled: lessThanTwoContentsSelected
            }, {
                label: this.$tc('product.contents.selectActions.deleteContents'),
                action: 'removeSelectedContents',
                disabled: noContentSelected || runningOrFinishedContentsSelected
            }]
        }
    },
    watch: {
        '$global.localization.locale' () {
            this.contentList.forEach(content => {
                this.setupContentForTableView(content)
            })
        }
    },
    mounted () {
        this.getContentList()

        // Watch the length of currently visible rows, in order to unselect rows that have been filtered after selection
        this.$watch(() => this.$refs.productContentsTable.getTableRows.length, () => {
            const visibleRowsIds = this.$refs.productContentsTable.getTableRows.map(row => row.id)
            this.contentList.forEach(content => {
                if (this.isContentSelected(content) && !visibleRowsIds.includes(content.id)) {
                    this.toggleSelected(content)
                }
            })
        })
    }
}
</script>

<template>
    <div class="generals-container"
         v-on:click="closeActiveInputs()">
        <div class="product-contents-container">
            <div class="product-contents-table-container"
                 tabindex="-1"
                 v-on:keyup="wrapperKeyPressedHandler($event)">
                <div class="product-contents-header">
                    <SmartSelect id="product-contents-actions"
                                 v-bind:key="`product-contents-actions_${selectedContentIds.length}_${this.isPlannable}`"
                                 v-bind:options="getSelectedContentsActions"
                                 v-bind:option-label-specifiers="['label']"
                                 v-bind:placeholder="$tc('product.contents.table.selectAction')"
                                 v-bind:is-disabled="selectedContentIds.length === 0 || isFrozen"
                                 v-bind:clear-input-on-submit="true"
                                 v-bind:sort-options="false"
                                 v-bind:allow-input="false"
                                 v-bind:submit-button="false"
                                 @select-change="executeSelectedContentsAction($event)">
                    </SmartSelect>
                    <IconButton class="product-contents-header-button"
                                v-bind:is-disabled="!isPlannable || newContent.visible || isFrozen"
                                icon-class="fas fa-plus"
                                v-bind:tooltip="$tc('product.contents.table.addContent')"
                                tooltip-placement="right"
                                @button-submit="showNewContentForm()">
                    </IconButton>
                    <VerticalLine></VerticalLine>
                    <span class="product-contents-header-page-count">{{`${$tc('product.contents.pageCount')} ${getPagesCount}`}}</span>
                    <div class="product-contents-table-header-button-wrapper">
                        <IconButton v-bind:icon-class="areAllSelected() ? 'fas fa-check-square' : 'far fa-square'"
                                    v-bind:is-disabled="isFrozen"
                                    v-bind:tooltip="areAllSelected()
                                    ? this.$tc('product.contents.table.unselectAllContents')
                                    : this.$tc('product.contents.table.selectAllContents')"
                                    v-if="this.isSelectedColumnVisible"
                                    @button-submit="toggleAllSelected()">
                        </IconButton>
                    </div>
                    <span v-if="showFrozenBadge">
                        <VerticalLine></VerticalLine>
                        <BadgeInput v-bind:value="this.$tc('product.contents.table.contentFrozen')" />
                    </span>
                </div>
                <div v-if="showSpinner" class="product-contents-spinner">
                    <span class="fas fa-circle-notch fa-spin"></span>
                    <span>&nbsp;&nbsp;{{ $tc('table.spinnerText') }}</span>
                    <span class="generals-walking-dots"></span>
                </div>
                <Table table-id="productContents"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="contentList"
                       v-bind:table-empty-message="getTableEmptyText"
                       v-bind:filter-placeholder="$tc('product.contents.table.filterPlaceholder')"
                       v-bind:filter-no-results-message="$tc('product.contents.table.filterNoResults')"
                       v-bind:read-only="true"
                       v-bind:allow-fullscreen="true"
                       v-bind:use-custom-first-element="true"
                       @column-visibility-change="handleColumnVisibilityChange"
                       ref="productContentsTable">
                    <template #cell(selected)="data">
                        <IconButton :is-disabled="!data.row.executableActions.select || isFrozen"
                                    v-bind:icon-class="isContentSelected(data.row) ? 'fas fa-check-square' : 'far fa-square'"
                                    @button-submit="toggleSelected(data.row)">
                        </IconButton>
                        <div class="product-contents-table-selection-index"
                             v-if="isContentSelected(data.row)">
                            <span>{{getContentPositionInSelection(data.row)}}</span>
                        </div>
                    </template>
                    <template #cell(name)="data">
                        <div v-if="data.row.info"
                             v-bind:id="data.row.id"
                             class="product-contents-info-label">
                            <span>{{data.row.info}}</span>
                        </div>
                        <InteractionLink v-if="!isInEditMode(data.row, contentFields.NAME)"
                                         class="generals-table-input"
                                         v-bind:value="data.row.name"
                                         v-bind:tooltip="isOldContentFinalized(data.row) ? $tc('product.contents.table.oldContentFinished') : ''"
                                         v-bind:is-disabled="!data.row.executableActions.editTitle"
                                         v-bind:is-underlined="data.row.executableActions.editTitle"
                                         icon-class="fas fa-edit"
                                         @input-click="setEditModeForField(data.row, contentFields.NAME, true)">
                        </InteractionLink>
                        <TextInput v-else
                                   v-bind:placeholder="$tc('product.contents.table.contentNamePlaceholder')"
                                   v-bind:submit-button="false"
                                   v-bind:default-value="data.row.name"
                                   v-bind:id="`${data.row.id}_inputContentName`"
                                   v-bind:is-auto-focused="true"
                                   v-bind:show-unsaved-changes="true"
                                   @input-blurred="updateContentField($event, data.row, contentFields.NAME)"
                                   @input-submit="updateContentField($event, data.row, contentFields.NAME)"
                                   @input-close="setEditModeForField(data.row, contentFields.NAME, false)"
                                   icon-class="fas fa-pen">
                        </TextInput>
                    </template>
                    <template #cell(pages)="data">
                        <InteractionLink v-if="!isInEditMode(data.row, contentFields.PAGES)"
                                         class="generals-table-input"
                                         v-bind:value="data.row.pages || '–'"
                                         v-bind:is-disabled="!data.row.executableActions.editPages || isFrozen"
                                         v-bind:is-underlined="data.row.executableActions.editPages"
                                         icon-class="fas fa-edit"
                                         @input-click="setEditModeForField(data.row, contentFields.PAGES, true)">
                        </InteractionLink>
                        <NumberInput v-else
                                     v-bind:minimum-value=0
                                     v-bind:default-value="data.row.pages"
                                     v-bind:id="`${data.row.id}_inputPages`"
                                     v-bind:is-auto-focused="true"
                                     v-bind:submit-button="false"
                                     v-bind:show-unsaved-changes="true"
                                     @input-close="setEditModeForField(data.row, contentFields.PAGES, false)"
                                     @input-blurred="updateContentField($event, data.row, contentFields.PAGES)"
                                     @input-submit="updateContentField($event, data.row, contentFields.PAGES)">
                        </NumberInput>
                    </template>
                    <template #cell(submissionDateDisplayText)="data">
                        <Icon v-if="!isInEditMode(data.row, contentFields.SUBMISSION)"
                              class="product-contents-table-edit-icon"
                              v-bind:icon-class="getIconClassForSubmissionDate(data.row)"
                              v-bind:tooltip="getTooltipForSubmissionDate(data.row)"
                        />
                        <InteractionLink v-if="!isInEditMode(data.row, contentFields.SUBMISSION)"
                                             class="generals-table-input"
                                             v-bind:value="data.row.submissionDateDisplayText"
                                             v-bind:is-disabled="!data.row.executableActions.editSubmission || isFrozen"
                                             v-bind:is-underlined="data.row.executableActions.editSubmission"
                                             icon-class="fas fa-edit"
                                             v-bind:text-color-class="isOverdue(data.row) ? 'error' : ''"
                                             @input-click="setEditModeForField(data.row, contentFields.SUBMISSION, true)">
                            </InteractionLink>
                            <DateInput v-else
                                       v-bind:default-value="data.row.submissionDate"
                                       v-bind:id="`${data.row.id}_submissionDate`"
                                       v-bind:is-auto-focused="true"
                                       v-bind:submit-button="false"
                                       v-bind:minimum-date="getNowDate()"
                                       v-bind:show-unsaved-changes="true"
                                       @input-close="setEditModeForField(data.row, contentFields.SUBMISSION, false)"
                                       @input-blurred="updateContentField($event, data.row, contentFields.SUBMISSION)"
                                       @input-submit="updateContentField($event, data.row, contentFields.SUBMISSION)">
                            </DateInput>
                    </template>
                    <template #cell(rolesDisplayText)="data">
                        <BadgeInput v-if="data.row.assignee"
                                    v-bind:is-disabled="true"
                                    v-bind:tooltip="getUserTagTooltip(data.row)"
                                    v-bind:value="data.row.rolesDisplayText"
                                    class="product-contents-assignee-tag">
                        </BadgeInput>
                        <span v-else>{{data.row.rolesDisplayText}}</span>
                    </template>
                    <template #cell(actions)="data" >
                        <template v-if="data.row.executableActions.progress || data.row.executableActions.repeat">
                            <IconButton v-bind:icon-class="getIconClassForStatusButton(data.row, isFrozen)"
                                        v-bind:tooltip="getToolTipForStatus(data.row.status, isFrozen)"
                                        v-bind:is-disabled="isFrozen"
                                        @button-submit="isContentFinalized(data.row) ? restartContent(data.row) : moveContentForward(data.row)">
                            </IconButton>
                            <VerticalLine></VerticalLine>
                        </template>
                        <InlineConfirmButton v-if="data.row.executableActions.delete"
                                             icon-class="fas fa-trash"
                                             v-bind:tooltip="$tc('product.contents.table.removeContent')"
                                             v-bind:confirm-message="$tc('generals.confirmDelete')"
                                             v-bind:is-disabled="isFrozen"
                                             @button-submit="removeContent(data.row)">
                        </InlineConfirmButton>
                        <Icon v-else-if="isContentInProgress(data.row)"
                              class="product-contents-table-status"
                              v-bind:tooltip="getToolTipMessageForStartedAndFinalContents(data.row)"
                              v-bind:icon-class="isContentFinalized(data.row) ? 'fas fa-flag-checkered' : 'fas fa-trash'"
                              v-bind:icon-type="isContentFinalized(data.row) ? 'dark' : 'inactive'"
                              v-bind:icon-large="isContentFinalized(data.row)">
                        </Icon>
                    </template>
                    <template v-if="newContent.visible"
                              v-slot:productContents_custom-element>
                        <div class="product-contents-new-content-label">
                            <span>{{$tc('product.contents.newContent.label')}}</span>
                        </div>
                        <table v-bind:id="newContentName"
                               class="product-contents-new-content"
                               v-on:keydown="newContentKeyPressedHandler($event)"><tbody>
                            <tr class="product-contents-new-content-tr">
                                <td class="product-contents-new-content-td"
                                    v-bind:style="{width: widths.selected  + '%'}">
                                </td>
                                <td class="product-contents-new-content-td"
                                    v-bind:style="{width: widths.name  + '%'}">
                                    <TextInput id="newInputContentName"
                                               v-bind:is-auto-focused="true"
                                               v-bind:placeholder="$tc('product.contents.newContent.contentNamePlaceholder')"
                                               v-bind:submit-button="false"
                                               @input-change="updateNewContentFormField($event, contentFields.NAME)"
                                               icon-class="fas fa-pen">
                                    </TextInput>
                                </td>
                                <td class="product-contents-new-content-td"
                                    v-bind:style="{width: widths.pages  + '%'}">
                                    <NumberInput v-bind:minimum-value=0
                                                 v-bind:submit-button="false"
                                                 @input-change="updateNewContentFormField($event, contentFields.PAGES)">
                                    </NumberInput>
                                </td>
                                <td class="product-contents-new-content-td"
                                    v-bind:style="{width: widths.submissionDate  + '%'}">
                                    <DateInput v-bind:minimum-date="getNowDate()"
                                               v-bind:submit-button="false"
                                               @input-change="updateNewContentFormField($event, contentFields.SUBMISSION)">
                                    </DateInput>
                                </td>
                                <td class="product-contents-new-content-td"
                                    v-bind:style="{width: widths.assignee  + '%'}">
                                </td>
                                <td class="product-contents-new-content-td"
                                    v-bind:style="{width: widths.actions + '%'}">
                                    <div class="new-content-actions">
                                        <IconButton icon-class="fas fa-check"
                                                    v-bind:tooltip="$tc('product.contents.newContent.saveAndExit')"
                                                    v-bind:is-disabled="addContentButtonDisabled"
                                                    @button-submit="addContent()">
                                        </IconButton>
                                        <VerticalLine></VerticalLine>
                                        <Button button-type="inline"
                                                class="new-content-stacked-icon-button"
                                                v-bind:is-disabled="addContentButtonDisabled"
                                                @button-submit="addContent(true)">
                                            <template v-slot>
                                                <span class="fa-stack"
                                                      v-bind:title="$tc('product.contents.newContent.saveAndContinue')">
                                                <i class="fas fa-check stacked-icon-first"></i>
                                                <i class="fas fa-plus-circle stacked-icon-second"
                                                    v-bind:class="{'m--disabled': addContentButtonDisabled}"></i>
                                            </span>
                                            </template>
                                        </Button>
                                        <VerticalLine></VerticalLine>
                                        <IconButton icon-class="fas fa-times"
                                                    v-bind:tooltip="$tc('generals.cancel')"
                                                    @button-submit="resetNewContent()">
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                        </tbody></table>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.product-contents-container {
    position: relative;
    width: 100%;
    height: 100%;

    .product-contents-table-container {
        height: 100%;
        outline: none;

        .product-contents-header {
            position: absolute;
            z-index: var(--z-index-header-button);

            ::placeholder {
                color: var(--color-text-mid);
            }

            .product-contents-header-button {
                margin-left: 8px;
            }

            .product-contents-header-page-count {
                margin-left: var(--container-spacing);
            }

            .product-contents-table-header-button-wrapper {
                width: 5%;
                position: absolute;
                top: 45px;
                left: 0;
                padding-left: 11px;
            }
        }

        .product-contents-spinner {
            position: absolute;
            top: 2%;
            left: 10%;
            width: 100%;
            height: 100%;
            font-size: 16px;
            border-spacing: 0;
            text-align: center;
        }

        .product-contents-table-selection-index {
            position: absolute;
            color: var(--color-text-bright);
            text-align: center;
            width: 15px;
            height: 15px;
            font-size: 12px;
            font-family: "Source Sans Pro Bold", sans-serif;
            background-color: var(--color-background-highlighted);
            border-radius: 50%;
            top: 27px;
            left: 30px;
            pointer-events: none;
        }

        .product-contents-info-label {
            width: fit-content;
            padding: 2px 8px;
            margin: 4px -6px 0 -6px;
            border-radius: 10px;
            background-color: var(--color-background-highlighted);
            color: var(--color-text-bright);
            outline: none;
            font-size: 12px;
        }

        .product-contents-table-edit-icon {
            display: inline-block;
            margin: 4px 4px 0 -8px;
        }

        .product-contents-assignee-tag {
            margin: 0;
            background-color: var(--color-info);
            color: var(--color-text-bright);
        }

        .product-contents-table-status {
            display: inline-block;
            margin-right: 5px;
        }

        .product-contents-new-content-label {
            position: absolute;
            width: 100%;
            font-size: 12px;
            font-family: "Source Sans Pro Bold", sans-serif;
            padding: 2px 0 2px 12px;
            color: var(--color-text-bright);
            background-color: var(--color-background-highlighted);
            z-index: var(--z-index-input-tiny-label);
        }
        .product-contents-new-content {
            width: calc(100% - 1px);
            border-collapse: collapse;

            .product-contents-new-content-tr {
                border-left: 1px solid var(--color-border-active);
                border-right: 1px solid var(--color-border-active);

                .product-contents-new-content-td {
                    padding: 32px 12px 14px 10px;
                    border-bottom: 1px solid var(--color-border-active);

                    .generals-input-container {
                        width: unset;
                    }
                }

                .new-content-actions {
                    float: right;

                    .new-content-stacked-icon-button {
                        min-width: 0;
                        padding: 0 8px;

                        .stacked-icon-first {
                            position: absolute;
                            top: 6px;
                            left: 8px;
                        }

                        .stacked-icon-second {
                            position: absolute;
                            top: 13px;
                            left: 16px;
                            font-size: 12px;
                            color: var(--color-info);

                            &.m--disabled {
                                color: var(--color-icon-inactive);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
