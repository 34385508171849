<script>
/**
 * DropInput is a pre styled file input element.
 * It will throw an @input-submit event when selecting a file or dropping files into the drop zone.
 * - label (optional): Text displayed as the label of the input field.
 * - placeholder (optional): Text displayed as the placeholder of the input field.
 * - allowMultiple (default: false): If true, multiple files can be dropped or selected.
 * - isDisabled (default: false): If true, the input field will be disabled.
 * - isDropZone (default: false): If true, the input field will have a 100% width, fitting its parent container.
 * - useDefaultLabel (default: false): If true, the label will not be positioned inside the dropzone, but next to it like the typical input labels.
 * - isUploading (default: false): While true, the input field will be disabled and show a spinner to indicate uploading process.
 * - fileTypes (optional): List of filetypes accepted by the input field e.g. ['.pdf', '.doc']. If not provided there will be no validation.
 * - uploadLimit (optional): If not empty, the input field will accept the given amount of files as maximum.
 **/
import { notificationHandler } from '@/mixins/notificationHandler'

export default {
    name: 'DropInput',
    mixins: [
        notificationHandler
    ],
    // @input-submit: Event emitted on input | returns the event and selected files
    emits: ['input-submit'],
    props: {
        label: String,
        placeholder: String,
        allowMultiple: {
            default: false,
            type: Boolean
        },
        isDisabled: {
            default: false,
            type: Boolean
        },
        isDropZone: {
            default: false,
            type: Boolean
        },
        useDefaultLabel: {
            default: false,
            type: Boolean
        },
        isUploading: {
            default: false,
            type: Boolean
        },
        fileTypes: Array,
        uploadLimit: Number
    },
    data () {
        return {
            dragActive: false
        }
    },
    methods: {
        setDragActive (active) {
            this.dragActive = active
        },

        emitSubmit (event) {
            event.stopPropagation()
            event.preventDefault()

            if (this.uploadLimit && event.target.files.length > this.uploadLimit) {
                return this.addNotification({
                    type: 'error',
                    duration: 10000,
                    message: this.$t('dropInput.fileLimitExceeded', [this.uploadLimit])
                })
            }
            const formData = new FormData()
            Object.values(event.target.files).forEach(file => {
                const fileEnding = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
                if (!this.fileTypes || this.fileTypes.includes(fileEnding.toLowerCase())) {
                    // noinspection JSCheckFunctionSignatures
                    if (file.size === 0) {
                        this.addNotification({
                            type: 'error',
                            duration: 10000,
                            message: this.$t('dropInput.emptyFile', [file.name])
                        })
                    } else {
                        formData.append('file', file)
                    }
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$t('dropInput.wrongFileType', [file.name, this.fileTypes.join(', ')])
                    })
                }
            })

            if (formData.getAll('file').length > 0) {
                this.$emit('input-submit', {
                    event: event,
                    value: formData
                })
            }
            event.target.value = null
        }
    },
    computed: {
        getLabel () {
            if (this.placeholder) {
                return this.placeholder
            }
            return (this.useDefaultLabel && !this.isDropZone) || !this.label
                ? this.$tc('dropInput.defaultPlaceholder')
                : this.label
        }
    }
}
</script>

<template>
    <div class="c_drop-input-wrapper generals-input-wrapper"
         v-bind:class="{'m--dropzone': isDropZone}">
        <label v-if="label && !isDropZone && useDefaultLabel"
               class="c_drop-input-default-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_drop-input-container generals-input-container">
            <input type="file"
                   v-bind:accept="fileTypes ? fileTypes.join(', ') : '*/*'"
                   class="c_drop-input generals-input"
                   v-bind:class="{'m--inactive': isUploading, 'm--disabled': isDisabled, 'm--dragover': dragActive}"
                   v-bind:multiple="allowMultiple"
                   v-on:dragenter="setDragActive(true)"
                   v-on:dragleave="setDragActive(false)"
                   v-on:drop="setDragActive(false)"
                   v-on:input="emitSubmit($event)"
                   v-on:click="$event.stopPropagation()" />
            <transition name="fade">
                <span v-if="!isUploading"
                      class="c_drop-input-icon fas fa-cloud-upload-alt"></span>
            </transition>
        </div>
        <transition name="fade">
            <div v-if="isUploading"
                 class="c_drop-input-loading-indicator"
                 v-bind:class="{'m--right-centered': useDefaultLabel && !isDropZone}">
                <div class="c_drop-input-loading-indicator-icon generals-breathing fas fa-cloud-upload-alt"></div>
                <div>
                    <span>{{$tc('dropInput.uploading')}}</span>
                    <span class="generals-walking-dots"></span>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <label v-if="!isUploading"
                   class="c_drop-input-label generals-input-label"
                   v-bind:class="{'m--right-centered': useDefaultLabel && !isDropZone}">
                <span>{{getLabel}}</span>
            </label>
        </transition>
    </div>
</template>

<style lang="less">
.generals-input-wrapper.c_drop-input-wrapper {

    &.m--dropzone {
        width: 100%;
        height: var(--drop-zone-height);

        .c_drop-input-loading-indicator {
            padding-top: 32px;
            font-size: 18px;

            .c_drop-input-loading-indicator-icon {
                font-size: 34px;
            }
        }

        .generals-input-label.c_drop-input-label {
            margin-top: 38px;
        }

        .generals-input-container.c_drop-input-container {
            width: 100%;
            height: 100%
        }
    }

    .generals-input-label.c_drop-input-default-label {
        padding-top: 3px;
    }

    .c_drop-input-loading-indicator {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 18px;
        font-size: 14px;

        &.m--right-centered {
            right: -180px;
        }

        .c_drop-input-loading-indicator-icon {
            font-size: 25px;
        }
    }

    .generals-input-label.c_drop-input-label {
        position: absolute;
        width: 100%;
        text-align: center;
        margin-top: 19px;
        color: var(--color-text-placeholder);
        font-family: "Source Sans Pro", sans-serif;
        font-weight: normal;
        pointer-events: none;

        &.m--right-centered {
            right: -195px;
        }
    }

    .generals-input-container.c_drop-input-container {
        height: var(--area-height);
        display: block;

        .generals-input.c_drop-input {
            color: var(--color-text-bright);
            border: 2px dashed var(--color-border-almost-white);
            border-bottom: 1px solid var(--color-border-dark);
            padding: 0;
            outline: none;
            text-align: center;
            text-indent: -999em;

            &.m--dragover {
                border-bottom: 2px dashed;
                border-color: var(--color-border-active);
                + span {
                    color: var(--color-icon-active);
                }
            }

            &.m--inactive {
                border-color: var(--color-border-light);
                pointer-events: none;
            }

            &.m--disabled {
                background-color: var(--color-background-disabled);
                border: 2px dashed var(--color-border-light);
                pointer-events: none;
                + span {
                    color: var(--color-icon-inactive);
                }
            }

            &:hover {
                cursor: pointer;
                border-bottom-color: var(--color-border-active);
                + span {
                    color: var(--color-icon-active);
                }
            }
        }

        .c_drop-input-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            pointer-events: none;
        }
    }
}
</style>
